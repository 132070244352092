<template>
  <div class="col-12 col-lg-8 c">
    <br><br>
    <div class="card card-body">
        <button class="btn btn-primary btn-lg" @click="send()">
                <i class="fa fa-paper-plane"></i>
                ارسال الرسائل الان
            </button>
        <div class="form-group dnone2 col-12 col-lg-6 c"><br><br>
        <label for="">اختر فصل معين</label>
        <select class="form-control" v-model="custom_classname" @change="gg()">
            <option value="no">الكل</option>
            <option v-for="c in classrooms" :key="c">{{ c }}</option>
        </select><br>
        </div>
        <table class="table table-bordered table-hover table-sm table-striped">
            <thead>
                <th>م</th>
                <th>الاسم</th>
                <th>الجوال</th>
                <th>
                    الصف
                </th>
                <th>
                    الفصل
                </th>
                <th>
                    الحالة
                </th>
            </thead>
            <tbody>
                <tr v-for="(student, i) in arr" :key="student.name">
                    <td>
                        {{ i+1 }}
                    </td>
                    <td>
                        {{ student.name }}
                    </td>
                    <td>
                        {{ student.phone }}
                    </td>
                    <td>
                        {{ student.classname }}
                    </td>
                    <td>
                        {{ student.classroom }}
                    </td>
                    <td>
                        {{ student.status }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <button
      class="dnone"
      id="notjoinstudentsalert" @click="messageChanged()"
      v-b-modal.modal-teachers-lessons-send2ss
    ></button>
    <b-modal id="modal-teachers-lessons-send2ss" size="lg" title="ارسال تنبيه" hide-footer>
      <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            view_archive: false,
            arr_o: [],
            arr: [],
            classrooms: [],
            custom_classname: "no",
            school_id: localStorage.getItem('school_id') ?? window?.location?.href?.split("?noor=")[1],
            teachers: [],
            message: `ابنكم {name}
{s} اليوم بتاريخ ${new Date().toISOString().split("T")[0]}`
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
       naqar_get("students_ghiab_report").then(function(r){
            g.arr = JSON.parse(r)
            g.arr_o = JSON.parse(r)
            g.arr.forEach(function(a){
                if(!g.classrooms.includes(a.classname + ' - ' + a.classroom)){
                    g.classrooms.push(a.classname + ' - ' + a.classroom);
                }
            })
        })
    },
    methods: {
        send(){
          $("#c-subscribe-ended").click()
          $("#after-send-settings-click").val("#notjoinstudentsalert")
        },
        messageChanged(){
            var g = this, c = [];
            g.arr.forEach(function(a){
              a.message = g.message?.replace("{name}", a.name)?.replace("{s}", a.status);
                c.push(a)
            })
            g.teachers = c
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
        },
        sendNow(method){
            var g = this;
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: localStorage.getItem('school_id') ?? window?.location?.href?.split("?noor=")[1],
                arr: JSON.stringify(g.teachers),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        gg(){
            var g = this;
            var n = [];
            g.arr_o.forEach(function(a){
                if(g.custom_classname == 'no'){
                    n.push(a)
                }else{
                    if(g.custom_classname == a.classname + ' - ' + a.classroom){
                        n.push(a)
                    }
                }
            })
            g.arr = n;
        }
    }
}
</script>